body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.page-maxwidth {
    max-width: 600px;
}

.page-maxwidth-large {
    max-width: 800px;
}

.page-margin {
    margin-top: 90px;
    margin-bottom: 80px;
    margin-left: 5%;
    margin-right: 5%;
}

.page-center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100vh;
}

.photo-avatar {
    height: 30px;
    width: 30px;
    
}

.logo-navbar {
    height: 30px;
    width: 30px;
}

.card-container {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
}

.card-item {
    min-width: 20rem;
    margin: 0.4rem;
}

.row-flex {
    display: flex;
    flex-direction: row;
}

.drop-zone {
    border-radius: 0.375rem;
    border: 2px dashed lightskyblue;
    background-color: #eaeaea;
}

.inner-drop-zone {
    display: flex;
    flex-direction: column;
    color: darkslategray;
    width: 100%;
    padding: 20px;
    align-items: center;
}

.field-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 0.375rem;
    border: 1px solid lightsalmon;
    padding: 5px;
}

.field-item {
    background-color: #eaeaea;
    margin: 2px;
    padding-inline: 10px;
}

.field-drop-zone {
    border-radius: 0.375rem;
    border: 2px dashed lightskyblue;
    background-color: #eaeaea;
    padding-inline: 10px;
    text-align: center;
}

.field-drop-zone-active {
    border-radius: 0.375rem;
    border: 2px dashed lightskyblue;
    background-color: lightsalmon;
    padding-inline: 10px;
    text-align: center;
}

.field-assigned-item {
    text-align: center;
}

.log-disp {
    padding-inline: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
}

.oc-log-table {
    font-size: small;
}

.nowrap {
    white-space: nowrap;
}

.input-options {
    max-height: 10rem;
    overflow: auto;
    margin-top: 10px;

    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    background: #6c757d;
}

.input-options-item {
    background-color: #6c757d;
    color: white;
    font-size: 0.875em;
}

.input-options-item:hover { 
    color: white;
    background-color: cornflowerblue;
}

.label {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #eaeaea;
    margin-right: 2px;
    margin-bottom: 3px;
    padding-inline: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: black;
}

.label-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: small;
    margin-top: 6px;
}

.label-group-container {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.label-group-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d;
}

.csv-disp {
    padding-inline: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
}
